<template>
  <base-material-card
    color="info"
    icon="mdi-umbrella-closed-outline"
    title="Opportunities"
  >
    <v-form
      ref="businessLinesForm"
      lazy-validation
      @submit.prevent="updateBusinessLines"
    >
      <v-autocomplete
        v-model="customer.lead_line_of_businesses"
        prepend-icon="mdi-umbrella-closed-outline"
        :items="mixinItems.businessLines"
        item-text="name"
        item-value="id"
        :loading="loadingMixins.businessLines"
        label="Business Lines"
        multiple
      />

      <v-btn
        fab
        absolute
        bottom
        right
        small
        type="submit"
        :loading="updating"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-form>
  </base-material-card>
</template>

<script>
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.businessLines,
      ]),
    ],

    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      updating: false,
    }),

    methods: {
      async updateBusinessLines () {
        if (this.$refs.businessLinesForm.validate()) {
          this.updating = true
          await new Api().put({
            url: 'customer/line-of-businesses',
            request: { lead_line_of_businesses: this.customer.lead_line_of_businesses, id: this.customer.id, user: this.customer.user },
          })
          this.updating = false
        }
      },
    },
  }
</script>
